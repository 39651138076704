body {
  background-color: #edf1f6;
}
.rdrDateDisplayWrapper {
  background-color: transparent !important;
}

.rdrDay.rdrDayToday .rdrDayNumber {
  border-top-right-radius: 1.042em;
  border-width: 1px;
  border-color: #093479;
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 2px;
  right: 2px;
  border-top-left-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
}

.rdrDayToday .rdrDayNumber span:after {
  content: none !important;
}

.rdrCalendarWrapper.rdrDateRangeWrapper {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.rdrDateRangePickerWrapper {
  border-radius: 8px;
}
.rdrDefinedRangesWrapper {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px;
  display: none;
}

.rdrStaticRange {
  border-top-left-radius: 8px;
}

.ant-carousel .slick-dots li button {
  background: white;
  border: 1px solid black;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  background: black;
}

.ant-modal-header {
  border-bottom: none;
  border-radius: 15px;
}

.ant-modal-content {
  border-radius: 15px;
}
.ant-picker,
.ant-picker-focused {
  border: none !important;
  box-shadow: none !important;
}

.ant-input:focus,
.ant-input,
.ant-input-affix-wrapper,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
  transition: none !important;
  background-color: transparent !important;
}

.ant-input-affix-wrapper.custom-inp:hover {
  border-color: #f9a8d4;
}

.ant-form-item.ant-form-item-has-error .custom-inp {
  border-color: #ff4d4f;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  transition: none !important;
}

.ant-popover {
  max-width: 200px;
}

.ant-popover-content {
  border-radius: 10px;
}

/* .ant-popover-inner {
  background-color: #231f20;
  border-radius: 10px;
}

.ant-popover-inner-content {
  color: white;
}

.ant-popover-arrow {
  background-color: #231f20 !important;
  border-color: #231f20 !important;
} */

.ant-spin-dot-item {
  background-color: #093479;
}

/* ._loading_overlay_overlay {
  position: fixed;
}
._loading_overlay_wrapper--active {
  overflow: hidden !important;
  height: 100vh;
} */
/* success */
.ant-form-item-has-success .custom-inp {
  content: " ";
  display: inherit;
  background: url("./images/success.svg") 96% 50% / 15px 15px no-repeat;
  width: 100%;
  height: 100%;
}

.ant-form-item-has-success .ant-input:focus,
.ant-form-item-has-success .ant-input:not(:focus),
.ant-form-item-has-success
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
  border-color: #58bb55;
  border-right-width: 1px !important;
  outline: 0;
}

/* error */
.ant-form-item-has-error .custom-inp {
  content: " ";
  display: inherit;
  background: url("./images/error.svg") 96% 50% / 14px 14px no-repeat;
  width: 100%;
  height: 100%;
}

.ant-form-item:not(.ant-form-item-has-error):not(.ant-form-item-has-success)
  .reqDot {
  height: 6px;
  width: 6px;
  background: #093479;
  border-radius: 50px;
  position: absolute;
  top: 42%;
  right: 16px;
}

/* for making select pink in select option  */
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: white;
  background-color: #093479;
}

/* For making scrollbar pink in select option dropdown */
.rc-virtual-list-scrollbar-thumb {
  background: #093479 !important;
}

.search-prod {
  position: relative;
}
.search-prod input {
  text-indent: 30px;
}
.search-prod .search-icon {
  position: absolute;
  top: 5px;
  left: 12px;
  font-size: 15px;
}

.bg-neu-bg {
  min-height: 100vh;
}

.ant-table-thead > tr > th {
  font-weight: 700;
  font-size: 11px;
  white-space: nowrap;
  word-wrap: break-word;
  word-break: break-all
}

.ant-table-tbody > tr > td {
  font-size: 12px;
}

.ant-form-item-explain-error > div {
  font-weight: 600;
  font-size: 12px;
  color: #e02020;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

span.ant-input-suffix {
  background-color: white;
  right: 35px;
  padding-left: 5px;
  position: absolute;
}

.ant-input-suffix {
  display: inline !important;
}
.progressVisualFull {
  display: flex;
  height: 6px;
  margin: 20px 0;
}
.progressVisualPart {
  transition: width 2s;
}

a:hover {
  color: #000;
}

.ant-table-thead .ant-table-cell {
  background-color: #eff2f5;
}

.tracking-tight {
  letter-spacing: 0px !important;
}

.anticon.anticon-exclamation-circle {
  height: min-content;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px; /* Adjust the padding as needed */
}

.ant-pagination {
  display: flex;
  align-items: center;
}

.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #093479;
}

.anticon.anticon-caret-up.ant-table-column-sorter-up.active,
.anticon.anticon-caret-down.ant-table-column-sorter-down.active {
  color: #093479;
}

.ant-pagination-item-link:hover {
  color: #093479 !important;
}

@media only screen and (max-width: 500px) {
  .ant-pagination,
  .ant-pagination-simple,
  .mini,
  .ant-table-pagination {
    margin-bottom: 50px !important;
  }
}
@media only screen and (min-width: 1100px) {
  .ant-pagination,
  .ant-pagination-simple,
  .mini,
  .ant-table-pagination {
    margin-left: 120px !important;
  }
}
@media only screen and (max-width: 1099px) {
  .ant-pagination,
  .ant-pagination-simple,
  .mini,
  .ant-table-pagination {
    margin-left: 10px !important;
  }
}

.ant-switch-checked {
  background-color: #093479 !important;
}
.table-striped-rows tr:hover td:first-child {
  border-left: 2px solid #093479 !important;
}
.table-striped-rows tr:hover td:last-child {
  border-right: 2px solid #093479 !important;
}
.table-striped-rows tr:hover td {
  border-bottom: 2px solid #093479 !important;
  border-top: 2px solid #093479 !important;
}
.ant-btn-primary {
  color: white !important;
  border-color: #093479 !important;
  background: #093479 !important;
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #093479 !important;
  border-color: none;
}
.ant-btn-link {
  color: black;
}
.ant-dropdown-menu-item-selected {
  color: black;
  background-color: transparent !important;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fde244 !important;
  border-color: #093479 !important;
  background: transparent;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #093479 !important;
  border-color: #093479 !important;
}
.ant-picker-today-btn {
  color: #093479 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #093479 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #093479 !important;
}

.ant-dropdown-menu-item .ant-dropdown-menu-item-active {
  border: 1px solid #093479 !important;
}

.ant-checkbox-checked::after {
  border: 1px solid #093479 !important;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  background-color: #d9d9d9;
}
.ant-checkbox-inner {
  border: 1px solid #d9d9d9 !important;
}
.ant-picker-month-btn:hover,
.ant-picker-year-btn:hover,
.ant-picker-decade-btn:hover {
  color: #093479 !important;
}
.ant-image-preview-img-wrapper{
  position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ant-modal-close{
  background-color: #fff;
  border-radius: 15px;
}
.ant-modal-wrap {
  z-index: 100;
}

.ant-modal-mask {
  z-index: 100;
}

.ant-row-color>td>h1 {
  color: red
}
.ant-row-color>td>div>h1{
  color: red
}

.ant-tabs-nav-wrap {
  border-bottom: 1px solid #3A6BBF !important;
}

.ant-tabs-tab {
  color: #3A6BBF !important;
  border:1px solid #3A6BBF !important;
  border-bottom: none !important;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
}

/* Active tab style */
.ant-tabs-tab-active .ant-tabs-tab-btn {
  color:  #3A6BBF !important;
  font-weight: bold;
  /* You can customize other styles for the active tab */
}

/* Hover tab style */
.ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #3A6BBF;
}

.activeButton{
  color: rgb(9, 52, 121);
  background-color: white;
  border:1px solid #3A6BBF !important;
  border-bottom: 1px solid #3A6BBF !important;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
}

.inActiveButton{
   color: #3A6BBF;
}

.ant-radio-checked .ant-radio-inner{
  border-color: rgb(77, 72, 72) !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: rgb(77, 72, 72);
}

.ant-table-container, .ant-table-container table>thead>tr>th {
  z-index: 0 !important;
}

.custom-steps .ant-steps-item-title {
  white-space: normal !important;
  word-wrap: break-word !important;
  font-size: 13px !important;
}

.custom-steps .ant-steps-item {
  margin-bottom: 10px;
}

.custom-steps .ant-steps {
  display: flex;
  justify-content: space-between;
}


/*  */
